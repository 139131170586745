import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

import { HeroHeader } from "../components/HeroHeader"
import { LargeHomeTeaser } from "components/LargeHomeTeaser"

import QuickLinksModule from "../components/QuickLinksModule/QuickLinksModule"
import LifeAtAuburn from "../components/LifeAtAuburn/LifeAtAuburn"
import { HomeStatement } from "../components/HomeStatement"

const IndexPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        posts(first: 4) {
          nodes {
            title
            link
            excerpt
            date
            featuredImage {
              sourceUrl
              srcSet
              mediaDetails {
                sizes {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  `)

  const homePosts = data.wpgraphql.posts.nodes

  const {
    homeBannerItems,
    homeQuickLinks,
    statementStrip,
    previewSQ,
  } = pageContext
  return (
    <Layout preview={previewSQ} pageContext={pageContext} >
      <SEO title="Home" />
      {homeBannerItems && (
        <HeroHeader homeBannerItems={homeBannerItems.items} />
      )}
      <HomeStatement statement={statementStrip.statementText} />
      {homeQuickLinks && <QuickLinksModule data={homeQuickLinks} />}
      {homePosts && <LifeAtAuburn homePosts={homePosts} />}
    </Layout>
  )
}

export default IndexPage
