import React from "react"
import { Block, Line } from "./style"

const HomeStatement = ({statement}) => {
  
  return (
    <Block>
      <Line dangerouslySetInnerHTML={{ __html: statement }} />
    </Block>
  )
}

export default HomeStatement
