import styled from "styled-components"
import { device } from "breakpoints"

export const Block = styled.div`
  padding: 58px 63px;
  text-align: center;

  @media ${device.desktop} {
    padding: 68px;
  }
`

export const Line = styled.div`
  font-size: 36px;
  color: #052c20;
  line-height: 54px;
  font-weight: 400;
  @media ${device.desktop} {
    font-size: 48px;
    line-height: 63px;
  }

  br {
    display: none;
    @media ${device.tablet} {
      display: block;
    }
  }

  strong {
    font-size: 54px;
    font-weight: 500;
    font-family: "Covered By Your Grace", arial;
    color: ${({ theme }) => theme.green};
    @media ${device.desktop} {
      font-size: 72px;
    }
  }
`
