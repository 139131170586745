import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { MainColumn } from "components/MainColumn"

export const LifeAtAuburnContainer = styled.div`
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  .greenLink {
    margin: 0 auto;
    margin-top: 50px;

    margin-bottom: 0px;

    @media ${device.desktop} {
      display: none;
    }
  }
`

export const LifeMainColumn = styled(MainColumn)`
  > h3 {
    margin-bottom: 20px;
    display: flex;
    line-height: 100%;

    @media ${device.desktop} {
      margin-bottom: 30px;
    }
    a {
      font-size: 18px;
      display: none;

      @media ${device.tablet} {
        margin-left: auto;
        display: inline;
        line-height: 100%;
        align-self: flex-end;
      }
    }
  }
`
