import React from "react"
import {
  QuickLinksModuleContainer,
  TextWrapper,
  GreenLink,
  QuickLinksWrapper,
  QuickLinkItem,
  GridWrapper,
  ImageWrapper,
} from "./style"
import { ReactComponent as Arrow } from "images/icons/arrow-right.svg"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"
import { Link } from "gatsby"

import AvenueImg from "components/AvenueImg"

const QuickLinksModule = ({ data }) => {
  return (
    <QuickLinksModuleContainer>
      <QuickLinksWrapper>
        <TextWrapper>
          <h4>{data.statement}</h4>

          <GreenLink to={data.link || "/"}>
            <span>{data.linkText}</span>
            <Arrow />
          </GreenLink>
        </TextWrapper>
        <GridWrapper>
          <FlexGrid fullWidth gap={[22, 22, 24, 24]}>
            <FlexRow>
              {data.quicklinks.map((item, index) => {
                return (
                  <FlexCol xs={12} md={4} key={index}>
                    <QuickLinkItem>
                      <ImageWrapper>
                        <AvenueImg fluid={item.featuredImage} />
                        <p>{item.excerpt}</p>
                      </ImageWrapper>
                      <Link to={item.link}>
                        <span>{item.title}</span>
                      </Link>
                    </QuickLinkItem>
                  </FlexCol>
                )
              })}
            </FlexRow>
          </FlexGrid>
        </GridWrapper>
      </QuickLinksWrapper>
    </QuickLinksModuleContainer>
  )
}

export default QuickLinksModule
