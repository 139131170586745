import React from "react"
import { LifeAtAuburnContainer, LifeMainColumn } from "./style"
import { LargeHomeTeaser } from "../LargeHomeTeaser"
import { Link } from "gatsby"
import { ThreeTeaserRow } from "components/Teaser"
import { GreenLink } from "components/QuickLinksModule/style"
import { ReactComponent as Arrow } from "images/icons/arrow-right.svg"

const teaserData = [
  "Sed ut eaueperss iste eaue tatis inventorere veritat",
  "Sed ut eaueperss iste eaue tatis inventorere veritat",
  "Sed ut eaueperss iste eaue tatis inventorere veritat",
]
const LifeAtAuburn = ({ homePosts }) => {
  const bigPost = homePosts.slice(0, 1)
  const threePosts = homePosts.slice(1, 4)

  return (
    <LifeAtAuburnContainer>
      <LifeMainColumn>
        <h3>
          Life at Auburn
          <Link to="/" className="underline">
            {" "}
            See more
          </Link>
        </h3>
        <LargeHomeTeaser data={bigPost[0]} />
      </LifeMainColumn>
      <ThreeTeaserRow data={threePosts} />

      <GreenLink className="greenLink" to="/">
        Default State <Arrow />
      </GreenLink>
    </LifeAtAuburnContainer>
  )
}

export default LifeAtAuburn
