import styled from "styled-components"
import { device } from "breakpoints"
import { Link } from "gatsby"
import AvenueLink from 'components/AvenueLink'

import { MainColumn } from "components/MainColumn"

export const QuickLinksModuleContainer = styled.div`
  background: #f2efe9;
  padding-top: 55px;
  padding-bottom: 45px;
  margin-bottom: 70px;
  @media ${device.desktop} {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 90px;
  }
`

export const QuickLinksWrapper = styled(MainColumn)`
  @media ${device.desktop} {
    flex-direction: row;
  }
`
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  position: relative;
  margin-bottom: 60px;

  @media ${device.desktop} {
    width: 32%;
    padding-right: 80px;
    margin-bottom: 0;
  }
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background: #026747;
  }
  h4 {
    font-size: 24px;
    line-height: 133%;
    margin-bottom: 30px;
    @media ${device.desktop} {
      margin-bottom: 30px;
    }

    @media (min-width: 1130px) {
      margin-bottom: 60px;
    }
  }
`

export const GreenLink = styled(AvenueLink)`
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  align-self: flex-start;
  color: white;
  background: ${props => props.theme.green};
  padding: 0 27px;
  border-radius: 24.75px;
  font-weight: 500;
  transition: 180ms;

  @media ${device.desktop} {
    font-size: 18px;
    padding: 0 30px;
    height: 55px;
    border-radius: 27.5px;
  }

  :hover {
    opacity: 0.75;
  }

  svg {
    width: 17px;
    height: 16px;
    margin-left: 5px;

    @media ${device.desktop} {
      width: 18px;
      height: 17px;
      margin-left: 9px;
    }
  }
`

export const GridWrapper = styled.div`
  @media ${device.desktop} {
    width: 68.376%;
    display: flex;
    align-items: center;
  }
`
export const QuickLinkItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  cursor: pointer;

  @media ${device.desktop} {
    height: 100%;
  }
  a {
    padding: 15px 20px;
    color: #043828;
    font-size: 16px;
    background: white;
    font-weight: 400;
    border-bottom: 3px solid #026747;
    :before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @media ${device.desktop} {
      padding: 12px 18px;
      border-bottom: 5px solid #026747;
    }

    @media ${device.large} {
      font-size: 18px;
    }
    span {
      background-image: linear-gradient(to right, #043828, #043828);
      background-position: 0 97%;
      background-repeat: no-repeat;
      background-size: 100% 0px;
    }
  }

  :hover {
    span {
      background-size: 100% 2px;
    }

    p {
      opacity: 1;
    }
  }
`

export const ImageWrapper = styled.div`
  padding-top: 53%;
  position: relative;
  width: 100%;
  background: #d8d8d8;

  @media ${device.desktop} {
    padding-top: 104%;
    height: 100%;
  }

  .avenueImg {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: 180ms;
    height: 100%;
    background: #026747;
    display: none;

    @media ${device.desktop} {
      display: block;
      font-size: 18px;
      color: white;
      font-weight: 400;
      padding: 20px;
      padding-top: 30px;
    }
  }
`
